/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, inject, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { PrivateService } from "src/app/private/private.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { firstValueFrom } from "rxjs";
import { NzMessageService } from "ng-zorro-antd/message";
import { patchState, signalState } from "@ngrx/signals";
import { TermoDeUso } from "../core/model";

@Component({
  selector: "app-termo-nao-assinado",
  templateUrl: "./termo-nao-assinado.component.html",
  styleUrls: ["./termo-nao-assinado.component.scss"],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatTooltipModule],
})
export class TermoNaoAssinadoComponent implements OnInit {
  private readonly privateService = inject(PrivateService);
  private readonly messageService = inject(NzMessageService);

  state = signalState({
    carregando: false,
    termoLido: false,
    aceitando: false,
    termoVigente: {} as TermoDeUso,
  });

  constructor(
    public sanitizer: DomSanitizer,
    private router: Router,
  ) {}

  async ngOnInit() {
    await this.retrieveTermoVigente();
  }

  async retrieveTermoVigente() {
    try {
      patchState(this.state, { carregando: true });
      const termoVigente = await firstValueFrom(
        this.privateService.getTermoVigente(),
      );
      if (termoVigente) {
        patchState(this.state, {
          termoVigente: {
            id: termoVigente.id,
            termo: termoVigente.termo.replace("<br>", "\n\n"),
            dataVigencia: termoVigente.dataVigencia,
          },
        });
      }
    } catch (error) {
      console.error("Erro ao recuperar termo vigente:", error);
      this.messageService.error("Falha ao carregar o termo vigente");
    } finally {
      patchState(this.state, { carregando: false });
    }
  }

  async confirmaAceite() {
    try {
      patchState(this.state, { aceitando: true });

      const termoId = this.state().termoVigente.id;
      if (termoId === undefined || termoId === null) {
        throw new Error("ID do termo não disponível");
      }

      await firstValueFrom(this.privateService.aceitarTermoVigente(termoId));

      this.router.navigate(["consultas"]);
    } catch (error) {
      this.messageService.error("Erro ao registrar Autorização de usuário");
      console.error("Erro ao aceitar termo vigente:", error);
    } finally {
      patchState(this.state, { aceitando: false });
    }
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight * 0.98
    ) {
      patchState(this.state, { termoLido: true });
    }
  }
}
