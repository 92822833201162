import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject, of } from "rxjs";
import {
  catchError,
  switchMap,
  tap,
  debounceTime,
  retry,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenStore } from "../auth/token.store";
import { HttpClient } from "@angular/common/http";
import { NzMessageService } from "ng-zorro-antd/message";
import { refreshToken } from "../auth/refresh-token";
import { LogoutService } from "../auth/logout.service";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  private readonly tokenStore = inject(TokenStore);
  private readonly http = inject(HttpClient);
  private readonly messageService = inject(NzMessageService);
  private readonly logoutService = inject(LogoutService);

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(null);
  private pendingRequests: Array<{
    request: HttpRequest<unknown>;
    next: HttpHandler;
    resolve: (value: HttpEvent<unknown>) => void;
    reject: (reason?: unknown) => void;
  }> = [];

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldSkipInterception(request)) {
      return next.handle(request);
    }

    return next.handle(this.addAuthHeaders(request)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private shouldSkipInterception(request: HttpRequest<unknown>): boolean {
    const shouldSkip =
      !this.tokenStore.token() ||
      request.url.startsWith(`${environment.apiUrl}oauth`) ||
      request.url.startsWith(`${environment.apiUrl}public`);

    // console.log(`Skipping interception: ${shouldSkip}`, request.url);
    return shouldSkip;
  }

  private addAuthHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const headers = this.buildHeaders(request);
    return request.clone({ setHeaders: headers });
  }

  private buildHeaders(request: HttpRequest<unknown>): Record<string, string> {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${this.tokenStore.token()}`,
      Accept: request.headers.get("Accept") || "application/json",
    };

    if (!this.isSpecialEndpoint(request)) {
      headers["Content-Type"] = "application/json";
    }

    return headers;
  }

  private isSpecialEndpoint(request: HttpRequest<unknown>): boolean {
    const specialEndpoints = ["imagem", "vetor", "salvarVetorFaces"];
    return specialEndpoints.some((endpoint) => request.url.endsWith(endpoint));
  }

  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return new Observable((observer) => {
        this.pendingRequests.push({
          request,
          next,
          resolve: observer.next.bind(observer),
          reject: observer.error.bind(observer),
        });

        if (this.pendingRequests.length === 1) {
          this.refreshToken()
            .pipe(
              debounceTime(100),
              tap(() => {
                this.isRefreshing = false;
                this.processPendingRequests();
              }),
              catchError((err) => {
                this.isRefreshing = false;
                this.handleAuthenticationFailure();
                this.rejectPendingRequests(err);
                return throwError(() => err);
              }),
            )
            .subscribe();
        }
      });
    } else {
      return new Observable((observer) => {
        this.pendingRequests.push({
          request,
          next,
          resolve: observer.next.bind(observer),
          reject: observer.error.bind(observer),
        });
      });
    }
  }

  private refreshToken(): Observable<void> {
    return refreshToken(this.http).pipe(
      switchMap((newToken) => {
        this.tokenStore.setToken(newToken);
        this.refreshTokenSubject.next(true);
        return of(undefined);
      }),
      retry(3),
      catchError((error) => {
        console.error("Interceptor: Erro ao tentar atualizar o token");
        throw error;
      }),
    );
  }

  private processPendingRequests(): void {
    this.pendingRequests.forEach(({ request, next, resolve, reject }) => {
      next.handle(this.addAuthHeaders(request)).subscribe({
        next: resolve,
        error: reject,
      });
    });
    this.pendingRequests = [];
  }

  private rejectPendingRequests(error: unknown): void {
    this.pendingRequests.forEach(({ reject }) => reject(error));
    this.pendingRequests = [];
  }

  private handleAuthenticationFailure(): void {
    console.log("handleAuthenticationFailure");
    setTimeout(() => this.logoutService.logoutAbAndLocal(), 1000);

    this.messageService.error(
      "Sua sessão expirou, por favor, faça login novamente.",
    );
  }
}
